<template>
  <v-container fluid>
    <!-- <v-card flat class="mb-3"> -->
    <v-form
      class="mb-6"
      style="width: 100%"
      v-model="valid"
      ref="form"
      v-if="token"
    >
      <v-card>
        <div class="pa-2">
          <div class="d-flex flex-row">
            <div style="width: 50%" class="mx-2">
              <v-select
                :label="$t('tokenType')"
                v-model="token.tokenType"
                type="text"
                maxlength="256"
                :rules="formRules.required"
                :items="tokenTypes"
                :no-data-text="$t('no_data')"
                :autofocus="true"
              >
                <!-- <template v-slot:item="{ item }">
                  <span :label="item.text">{{ item.text }}</span>
                </template> -->
              </v-select>
              <GsDatePicker
                :label="$t('nbf')"
                v-model="token.nbf"
                :rules="formRules.optional"
                :max="token.exp"
              />
              <GsDatePicker
                :label="$t('exp')"
                v-model="token.exp"
                :rules="formRules.optional"
                :min="token.nbf"
              />
              <v-text-field
                :label="$t('proxyIdentityId')"
                v-model="token.proxyIdentityId"
                type="text"
                maxlength="256"
                :rules="formRules.optional"
              />
              <!-- <v-autocomplete
                :label="$t('ownedBy')"
                v-model="token.owner"
                :items="customers"
                :rules="formRules.optional"
              /> -->
              <GsInfiniteDropdown
                :config="customerSelectionConfig"
                v-model="token.owner"
              />
              <GroupsDropdown
                :label="$t('groups')"
                v-model="token.group"
                :rules="formRules.optional"
                :ownedBy="token.owner"
              />
            </div>
          </div>

          <!-- errors -->
          <div class="d-flex flex-row" v-if="error">
            <span class="subtitle-1 mb-4 red--text"
              >{{ $t("form_invalid_text") }}: {{ error }}</span
            >
          </div>

          <!-- <actions> -->
          <div class="d-flex flex-row">
            <v-spacer />
            <v-btn class="ma-2" color="default" @click="cancel_click">
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              class="ma-2"
              color="primary"
              @click="save_click"
              :loading="loading"
            >
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("save") }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import GsDatePicker from "@/components/GsDatePicker";
import GroupsDropdown from "@/components/FormComponents/GroupsDropdown";
import { GsInfiniteDropdown } from "ngt-frontend-core";
const lookups = require("@/app-data/lookups.json");
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "IdentityIdentity",
  components: {
    GsDatePicker,
    GroupsDropdown,
    GsInfiniteDropdown
  },
  data() {
    return {
      mode: null,
      token: null,
      formRules: {
        required: [
          value =>
            typeof value === "undefined" || value === null || value === ""
              ? this.$t("field_required")
              : true
        ],
        requiredArray: [
          value =>
            typeof value === "undefined" ||
            value === null ||
            value === "" ||
            (Array.isArray(value) && value.length === 0)
              ? this.$t("field_required")
              : true
        ],
        optional: [value => true]
      },
      valid: true,
      error: null,
      loading: false
    };
  },
  computed: {
    customerSelectionConfig() {
      return {
        dataConnector: callBffAPI,
        dataFields: {
          uri: "/customers",
          sort: "name:asc",
          fields: "name,_uri",
          search: "name:ic:",
          dataMap: {
            title: "name",
            value: "_uri"
          }
        },
        label: this.$t("ownedBy"),
        limit: 50,
        page: 0,
        rules: this.formRules.required,
        value: null
      };
    },
    customers() {
      return this.$store.getters.customers();
    },
    tokenTypes() {
      return lookups.tokenTypes;
    }
  },
  methods: {
    createToken(identityId) {
      // console.log("createToken", identityId)
      this.token = {
        identityId: identityId,
        tokenType: null,
        nbf: null,
        exp: null,
        proxyIdentityId: null,
        owner: null,
        group: null
      };
    },
    // async loadIdentity(identityId, tokenId) {
    //   const url = `${this.$store.state.idp_api_url}/identities/${identityId}/tokens${tokenId}`;
    //   const method = "GET";
    //   try {
    //     const response = await callAPI({ url, method });
    //     this.token = response.data;
    //     console.log(this.token)
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },
    async cancel_click() {
      this.$emit("cancel");
    },
    async save_click() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close")
          }
        });
        return;
      }

      const url = `${this.$store.state.idp_api_url}/identities/${this.token.identityId}/tokens`;
      const method = "POST";
      let data = {
        tokenType: this.token.tokenType
      };
      // if (this.identity.password) data.password = this.identity.password;

      if (this.token.nbf !== null) data.nbf = this.token.nbf;
      if (this.token.exp !== null) data.exp = this.token.exp;
      if (this.token.proxyIdentityId !== null)
        data.proxyIdentityId = this.token.proxyIdentityId;
      if (this.token.owner !== null) data.owner = this.token.owner;
      if (this.token.group !== null) data.group = this.token.group || [];

      // console.log("to be saved ", data)

      try {
        this.loading = true;
        const response = await callAPI({ url, method, data });
        // console.log(response.status)
        if (response.status == 204 || response.status == 200) {
          this.$emit("save");
        }
      } catch (err) {
        const data = err?.response?.data;
        // console.log(data);

        if (data.statusCode == 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
