<template>
  <v-container fluid class="pa-8">
    <!-- <v-row>
      <v-sheet
        outlined
        style="width: 100%"
        class="py-4 pl-2"
        color="primary"
        dark
      >
        <span>{{ $t("name") }}: </span>
        <span>{{ identityName }}</span>
      </v-sheet>
    </v-row> -->
    <v-row class="pb-4">
      <GsNavButtons />
    </v-row>
    <v-row class="d-flex flex-row align-center">
      <div style="width: 100%" class="mt-2">
        <v-btn
          small
          color="primary"
          @click="add_click"
          v-if="status != statusValues.add && status != statusValues.edit"
        >
          {{ $t("add_button") }}
        </v-btn>
      </div>
    </v-row>
    <v-row>
      <v-card
        style="width: 100%"
        flat
        v-if="status == statusValues.add || status == statusValues.edit"
      >
        <GsTokenForm
          ref="tokenform"
          @cancel="cancel_click"
          @save="save_click"
        />
      </v-card>
    </v-row>
    <v-row>
      <v-container fluid class="mt-0 pa-0">
        <!-- filter panel -->
        <GsDataFilter
          ref="dataFilter"
          :appSetting="appSettingKey"
          :filters="filters"
          v-on:changed="onFilterChanged"
        />
        <!-- table -->
        <GsDataTable
          ref="table"
          api="idp_api_url"
          :endpoint="endpoint"
          :appSetting="appSettingKey"
          :headers="headers"
          :sortBy="['createdAt']"
          :sortDesc="[true]"
          :beforeCallApi="beforeCallApi"
          :afterCallApi="afterCallApi"
          @after-refresh="afterRefresh"
        >
          <template v-slot:item.token="{ item }">
            <input type="text" :value="item.token" disabled class="mr-4 my-2" />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="mr-4 my-2"
                  @click="copyToken(item.token)"
                  >mdi mdi-content-copy</v-icon
                >
              </template>
              <span>{{ $t("copy_to_clipboard") }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.nbf="{ item }">
            <span>{{ item.nbf | getDisplayDate }}</span>
          </template>

          <template v-slot:item.exp="{ item }">
            <span>{{ item.exp | getDisplayDate }}</span>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <span
              >{{ item.createdAt | getDisplayDate }} ({{
                item.createdBy.name | empty
              }})</span
            >
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mr-2"
              small
              color="error"
              v-on:click="delete_click(item)"
              >{{ $t("delete_button") }}</v-btn
            >
          </template>
        </GsDataTable>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsIdentityTokenDetails from "../components/GsIdentityTokenDetails";
import GsTokenForm from "@/components/GsTokenForm";
import { GsDataTable, GsDataFilter } from "ngt-frontend-core";
import GsNavButtons from "@/components/GsNavButtons";

export default {
  name: "IdentityTokensPage",
  components: {
    GsIdentityTokenDetails,
    GsTokenForm,
    GsDataTable,
    GsDataFilter,
    GsNavButtons
  },
  data() {
    return {
      appSettingKey: "identitiyTokens",
      endpoint: null,
      tokens: [],
      status: 0,
      statusValues: {
        browse: 0,
        add: 1,
        edit: 2
      },
      token: null,
      formConfig: {},
      identityName: null,
      filters: [
        // {
        //   field: "client_name",
        //   component: "GsTextFilter",
        //   params: {}
        // },
        {
          field: "tokenType",
          component: "GsSelectFilter",
          params: {
            options: this.$store.state.lookups.tokenTypesForFilter
          },
          width: "wide"
        }
      ],
      identityId: null,
      tokenType: null
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("tokenType"), align: "left", value: "tokenType" },
        { text: this.$t("token"), value: "token", sortable: false },
        { text: this.$t("nbf"), value: "nbf" },
        { text: this.$t("exp"), value: "exp" },
        { text: this.$t("createdAt"), value: "createdAt" },
        { text: this.$t("actions"), sortable: false, value: "actions" }
      ];
    }
  },
  watch: {
    "$route.params.identityId": {
      immediate: false,
      handler(v) {
        this.identityId = v;
        this.init();
      }
    },
    "$route.query.tokenType": {
      immediate: false,
      handler(v) {
        this.tokenType = v;
        this.init();
      }
    }
  },
  async created() {
    if (this.$route.params.identityId) {
      this.identityId = this.$route.params.identityId;
    }

    if (this.$route.query.tokenType) {
      this.tokenType = this.$route.query.tokenType;
    }

    this.identityName = await this.getIdentityName(
      this.$route.params.identityId
    );

    this.init();
  },
  methods: {
    async init() {
      if (this.tokenType) {
        this.$refs.dataFilter.$refs.filter_tokenType[0].setValue(this.tokenType);
      }

      await this.setBreadCrumbs();
      this.endpoint = `/identities/${this.identityId}/tokens`;
      setTimeout(async () => {
        await this.$refs.table.refreshTable();
      });
    },
    async refreshTable() {
      if (!this.$refs.table) return;
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      params.url += this.$refs.dataFilter.getFilter();
      params.url += "&r8sFields=createdBy.name";
      return params;
    },
    async afterCallApi(params) {
      return params;
    },
    afterRefresh({ items }) {
      this.tokens = items;
    },
    async add_click() {
      this.status = this.statusValues.add;
      this.expanded = [];
      setTimeout(() => {
        this.$refs.tokenform.createToken(this.$route.params.identityId);
      });
    },
    async cancel_click() {
      this.status = this.statusValues.browse;
    },
    async save_click() {
      this.status = this.statusValues.browse;
      await this.refreshTable();
    },
    async delete_click(item) {
      let result = confirm(this.$t("delete_modal") + "\n\n" + item.tokenId);
      if (!result) return;

      await callAPI({
        url:
          this.$store.state.idp_api_url +
          "/identities/" +
          this.$route.params.identityId +
          "/tokens/" +
          item.tokenId,
        method: "DELETE"
      });
      await this.refreshTable();
    },
    async copyToken(item) {
      let tempElem = document.createElement("input");
      tempElem.value = item;
      document.body.appendChild(tempElem);
      tempElem.select();
      tempElem.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");

      // alert("This token is copied to clipboard: \n \n" + tempElem.value);
      const res = await this.$dialog.info({
        text: this.$t("token_copied_to_clipboard") + ": \n \n" + tempElem.value,
        title: "Info"
      });

      document.body.removeChild(tempElem);
    },
    onFilterChanged() {
      this.$refs.table.goToFirstPage();
    }
  }
};
</script>

<style lang="scss" scoped></style>
